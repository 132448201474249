const whitePath = [
    '/login',
    '/home/index',
    '/login/qrcode',
    '/login/account',
    '/login/reg',
    '/loginMoble',
    '/loginMoble/qrcode',
    '/loginMoble/account',
    '/loginMoble/reg',
    '/',
    '/404',
    '/403',
    '/anchor/search',
    '/anchor/data'
  ]
  export { whitePath}
