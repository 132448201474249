<template>
    <div class="le-Tabs-container"  >
        <div :class="index==idx?'list-item active':'list-item'" v-for="(item,idx) in list"  :key="idx"  @click="tabItem(idx)" >{{item}}</div>
        <a-range-picker style="width: 200px;" @change="onChange" />
    </div>
</template>

<script>
import moment from 'moment';
export default {
  name: "le-tabs",
  components: {
  },
  data() {
    return {
      index:0
    };
  },
  props:{
    list:{
      type:Array,
      default:()=>{
        return [
          '昨日',
          '上周',
          '本周',
          '本月'
        ]
      }
    }
  },
  computed: {
  },
  mounted(){
    // const json={
    //     start:moment(new Date()).add(-1, 'days').format('YYYY-MM-DD 00:00:00'),
    //     end:moment(new Date()).add(-1, 'days').format('YYYY-MM-DD 23:59:59')
    //   }
    // console.log('json',json)
    // this.$emit('change',json)
  },
  methods: {
    onChange(date, dateString){
      console.log("onChange",date, dateString);
      this.index = 4;
      const json={
        start:dateString[0]+' 00:00:00',
        end:dateString[1]+' 23:59:59',
      }
      this.$emit('change',json)
    }, 
    tabItem(idx){
      const json={
        start:'',
        end:''
      }
      switch(idx){
        case 0:
          json.start = moment(new Date()).add(-1, 'days').format('YYYY-MM-DD 00:00:00');
          json.end = moment(new Date()).add(-1, 'days').format('YYYY-MM-DD 23:59:59');
        break;
        case 1:
          json.start = moment().day("Monday").day(-6).format("YYYY-MM-DD 00:00:00");
          json.end = moment().day("Monday").format("YYYY-MM-DD 23:59:59");
        break;
        case 2:
          json.start = moment().day("Monday").format("YYYY-MM-DD 23:59:59");
          json.end = moment().day("Monday").day(+7).format("YYYY-MM-DD 23:59:59");
        break;
        case 3:
          json.start = moment().startOf("month").format("YYYY-MM-DD 00:00:00");
          json.end = moment().endOf("month").format("YYYY-MM-DD 23:59:59");
        break;

      }
      console.log(json);
      this.index=idx
      this.$emit('change',json)
    }
  }
};
</script>

<style lang="less" scoped>
.le-Tabs-container {
  display: flex;
  margin-right: 20px;
  .list-item{
    min-width: 70px;
    height: 30px;
    background: #FFFFFF;
    box-shadow: 0px 0px 5px 0px rgba(205, 206, 210, 0.5);
    border-radius: 5px;
    text-align: center;
    line-height: 30px;
    font-weight: 400;
    color: #303133;
    margin-right: 20px;
    cursor: pointer;
    transition: .4s;
    padding: 0 10px;
    font-size: 14px;
  }
  .active{
    background: linear-gradient(-10deg, #69C6FF, #3E83FF);
    box-shadow: 0px 3px 5px 0px #A3BFFF;
    border-radius: 5px;
    color: #FFFFFF;
  }
}
</style>
