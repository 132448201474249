import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex)

const state = {
    menus: [],
    blackPath: [],
    buttons: [],
    info: {},
    isMobile: true,
}

export default new Vuex.Store({
    state
})


