import Vue from 'vue'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

Vue.filter('NumberFormat', function (value) {
  if (!value) {
    return '0'
  }
  const intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断
  return intPartFormat
})

Vue.filter('dayjs', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
})

Vue.filter('moment', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
})

Vue.filter('kong', function (dataStr) {
  if((dataStr==0||dataStr=='0')&&dataStr!=''){
    return 0;
  }

	if(dataStr==null||dataStr==undefined||dataStr==''||dataStr=='null'||dataStr=='undefined'){
		return '--'
	}else return dataStr
})

// Vue.filter('money',function (s){
//   if (!s) s = 0;
//   s = String(s);
//   s = s.replace(/^(\d*)$/, "$1.");
//   s = (s + "00").replace(/(\d*\.\d\d)\d*/, "$1");
//   s = s.replace(".", ",");
//   var re = /(\d)(\d{3},)/;
//   while (re.test(s)) s = s.replace(re, "$1,$2");
//   s = s.replace(/,(\d\d)$/, ".$1");
//   return s.replace(/^\./, "0.");
// })

Vue.filter('day', function (dataStr) {
	if(dataStr==null||dataStr==undefined||dataStr==''||dataStr=='null'||dataStr=='undefined'||dataStr==0||dataStr=='0'){
		return '--'
	}else{
    return dataStr.split(' ')[0]
  } 
})

Vue.filter('money',function (money){
  if (money && money != null &&!isNaN(money)) {
    money = parseFloat(money).toFixed(2)
    money = String(money);
    var left = money.split('.')[0], right = money.split('.')[1];
    right = right ? (right.length >= 2 ? '.' + right.substr(0, 2) : '.' + right + '0') : '.00';
    var temp = left.split('').reverse().join('').match(/(\d{1,3})/g);
    return (Number(money) < 0 ? '-' : '') + temp.join(',').split('').reverse().join('') + right;
  } else if (money === 0) { // 注意===在这里的使用，如果传入的money为0,if中会将其判定为boolean类型，故而要另外做===判断
    return '0.00';
  } else {
    return '0.00';
  }
})